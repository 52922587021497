import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';

import './App.css';
import { Navbar } from 'react-bootstrap';
import Home from './pages/Home/Home';
import NavBar from './components/NavBar/Navbar';
import LoginPage from './pages/Login/Login';
import ContactUs from './pages/ContactUs/Contact';
import Footer from './components/Footer/Footer';
import AboutUsPage from './pages/About/About';
import ServicePage from './pages/Services/ServicePage';
import SignupPage from './pages/Login/Signup';
import Admin from './pages/deashbord/Admin/Admindord';
import UsersPage from './pages/deashbord/Admin/UsersPage/UsersPage';
import ServicesPage from './pages/serv/services';
import ServiceDetailPage from './pages/serv/serviceDetails';

function App() {

  
  return (
    <div className="App">
       <NavBar/>
      <Routes>
     
      <Route path='/' element={<Home/>}/>
      <Route path='/About' element={<AboutUsPage/>}/>
      <Route path='/Contact' element={<ContactUs/>}/>
      <Route path='/service' element={<ServicePage/>}/>
      <Route path='/Login' element={<LoginPage/>}/>
      <Route path='/Signup' element={<SignupPage/>}/>
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/services/:serviceId" element={<ServiceDetailPage />} />
      {/* admin */}
      <Route path='/Admin' element={<Admin/>}>
         <Route path='Users' element={<UsersPage/>}/>

      </Route>
     
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
