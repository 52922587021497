import { Outlet } from "react-router-dom";
import AdminSidbare from "./AdminSidbare";
import "./Admin.css"



export default function Admin (){

    return(
<div className="admin-page">
    <div className="sidebare">
    <AdminSidbare/>
    </div>

    <div className="contant">
    <Outlet/>
    </div>
</div>
    );
}

