import { useState } from "react";
import heroPic from "../../assets/pictur/sports-academy-hero.png"; // Image representing sports and athletic performance
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import "./Hero.css";
import { Link } from "react-router-dom";

export default function Hero() {
    const stats = [
        {
            data: "50K",
            title: "Registered Students"
        },
        {
            data: "200+",
            title: "Available Courses"
        },
        {
            data: "15",
            title: "Professional Trainers"
        },
        {
            data: "10",
            title: "International Partners"
        },
    ];

    return (
        <div className="hero-section">
            <section className="py-10">
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
                        <h1 className="text-sm text-600 font-medium">
                            Join over 200 successful training courses
                        </h1>
                        <h2 className="text-4xl mb-5 text-gray-800 font-extrabold md:text-5xl">
                            Elevate Athletic Performance <span>to a Global Level</span>
                        </h2>
                        <p>
                            We offer the best training courses that help athletes and trainers achieve top results through sports science and hands-on coaching.
                        </p>
                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                            <Link to="/Login">  
                                <button className="button-one block no-underline py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none">
                                    Get Started Now
                                </button>
                            </Link>
                            <Link to="/Signup" className="button-two flex no-underline items-center justify-center gap-x-2 py-2 px-4 text-gray-700 active:bg-gray-100 border rounded-lg md:inline-flex">
                                Get Access
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="hero-pic flex-none mt-14 md:mt-0 md:max-w-xl">
                        <img
                            src={heroPic}
                            className=" md:rounded-tl-[108px]"
                            alt="Athletic Performance"
                        />
                    </div>
                </div>
                <div className="mt-2 px-4 md:px-8">
                    <p className="text-center text-sm text-gray-700 font-semibold">Follow us on social media</p>
                    <div className="icons flex justify-center items-center flex-wrap gap-x-12 gap-y-6 mt-2">
                        <a href=""><FaFacebook /></a> 
                        <a href=""><FaInstagram /></a> 
                        <a href=""><FaTwitter /></a> 
                        <a href=""><FaYoutube /></a> 
                    </div>
                </div>
            </section>

            {/* Statistics */}
            <section className="py-1">
                <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                    <div className="max-w-2xl mx-auto text-center">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                            Tremendous Success for Our Students and Trainers
                        </h3>
                    </div>
                    <div className="mt-12">
                        <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                            {
                                stats.map((item, idx) => (
                                    <li key={idx} className="text-center px-30 md:px-16">
                                        <h4 className="text-4xl text-indigo-600 font-semibold">{item.data}</h4>
                                        <p className="mt-3 font-medium">{item.title}</p>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>

        </div>
    );
}
