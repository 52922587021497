import React from 'react';

export default function ServiceDetailPage({ service, onBack }) {
  return (
    <div className="service-detail-page">
      <button onClick={onBack} className="back-button">← Back to Services</button>
      <h1>{service.title}</h1>
      <p>{service.description}</p>
      <p>Here you can add more details about the {service.title}.</p>
    </div>
  );
}
