
import AboutSection from '../../components/AboutSection/AboutSection';
import ContactSection from '../../components/Contact Section/ContactSection';
import Hero from '../../components/HeroSection/Hero';
import PricingSec from '../../components/PricinSection/Pricin';
import ServiceSection from '../../components/service Section/ServiceSection';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <Hero/>
      <ServiceSection/>
      <AboutSection/>
      <ContactSection/>
      <PricingSec/>

    </div>
  );
}

export default Home;
