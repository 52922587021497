import React from 'react';
import './services.css';

// أمثلة على الخدمات
const services = [
  {
    id: 1,
    title: "Performance Analyst Course",
    description: "Analyze team performance with video and data analysis.",
    price: "$150",
    image: "https://img.freepik.com/free-vector/man-running-with-blue-flowing-wave_1017-9202.jpg?t=st=1727997820~exp=1728001420~hmac=c9796c4c6b28b2df56d9a8d845d2532413aa9ff32dd104951e297e8ff0d2ce83&w=740",
  },
  {
    id: 2,
    title: "Strength and Conditioning Course",
    description: "Learn best practices in strength and conditioning.",
    price: "$200",
    image: "https://img.freepik.com/free-photo/satisfied-bearded-fitness-man-sunglasses-holding-sports-equipment_171337-15827.jpg?ga=GA1.2.714162669.1700214390&semt=ais_hybrid",
  },
  {
    id: 3,
    title: "Sports Nutrition Course",
    description: "Master the science of sports nutrition.",
    price: "$120",
    image: "https://img.freepik.com/premium-psd/assorted-sports-equipment-isolated-white-background_1030599-3912.jpg?ga=GA1.2.714162669.1700214390&semt=ais_hybrid",
  },
  {
    id: 4,
    title: "Conferences and Workshops",
    description: "Join international conferences with sports experts.",
    price: "$100",
    image: "https://img.freepik.com/free-photo/portrait-person-practicing-sport_23-2151059725.jpg?ga=GA1.2.714162669.1700214390&semt=ais_hybrid",
  },
  {
    id: 5,
    title: "Human Performance Laboratory",
    description: "Access advanced testing services for athletes.",
    price: "$250",
    image: "https://img.freepik.com/free-psd/sport-tech-square-flyer-template_23-2148450856.jpg?ga=GA1.2.714162669.1700214390&semt=ais_hybrid",
  },
];

export default function ServicesPage({ onSelectService }) {
  return (
    <div className="services-page">
      <h1 className="text-center text-4xl font-bold my-8">Our Services</h1>
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service) => (
          <div key={service.id} className="service-card p-4 shadow-lg rounded-lg">
            <img src={service.image} alt={service.title} className="w-full h-48 object-cover mb-4 rounded-md" />
            <h2 className="text-2xl font-bold mb-2">{service.title}</h2>
            <p className="text-lg font-semibold text-indigo-600 mb-4">{service.price}</p>
            <p className="text-gray-700 mb-4">{service.description}</p>
            <button
              onClick={() => onSelectService(service)}
              className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
            >
              See More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
